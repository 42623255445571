<template>
  <div class="adx-chart-container">
    <div class="adx-chart-left">
      <div class="title">数据趋势</div>
      <a-spin class="spin" :spinning="isLoading"><div class="chart" ref="chart"></div></a-spin>
    </div>
    <div class="adx-chart-right">
      <div class="right-head">选择指标</div>
      <div class="tag-list">
        <div
          class="tag-item"
          :class="{active: currentChecked === item.value}"
          @click="() => {
            if (currentChecked === item.value) return
            currentChecked = item.value
            changeEcharts()}"
          v-for="item in checkList"
          :key="item.value">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFullTrend } from '@/apiForManage/report/synthReport'
import { numFormat } from '@/utils/dealNumber'
import options from './options'
import * as echarts from 'echarts'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  mixins: [rateAndMoneyMethod],
  data () {
    return {
      currentChecked: 'impress',
      options,
      checkList: [
        {
          label: '流量请求',
          value: 'request'
        },
        {
          label: '流量填充率',
          value: 'requestFilledRate'
        },
        {
          label: '流量填充',
          value: 'response'
        },
        {
          label: '广告请求',
          value: 'advRequest'
        },
        {
          label: '广告填充',
          value: 'advResponse'
        },
        {
          label: '广告填充率',
          value: 'advRequestFilledRate'
        },
        {
          label: '展示',
          value: 'impress'
        },
        {
          label: '展示率',
          value: 'impressRate'
        },
        {
          label: '展请率',
          value: 'impressRequestRate'
        },
        {
          label: '点击',
          value: 'click'
        },
        {
          label: '点击率',
          value: 'clickRate'
        },
        {
          label: '媒体消耗',
          value: 'income'
        },
        {
          label: '广告收入',
          value: 'advPrice'
        },
        {
          label: '利润率',
          value: 'profitRate'
        },
        {
          label: '广告收入eCPM',
          value: 'advEcpm'
        },
        {
          label: '媒体成交eCPM',
          value: 'estimatedRevenueEcpm'
        },
        {
          label: '竞价响应eCPM',
          value: 'askPrice'
        },
        {
          label: '媒体消耗CPC',
          value: 'cpc'
        },
        {
          label: '广告成交CPC',
          value: 'advCpc'
        }
      ],
      isLoading: false,
      dataList: [],
      colorList: [
        {
          c1: 'rgba(183, 105, 247, 1)',
          c2: 'rgba(138, 89, 251, 1)'
        },
        {
          c1: 'rgba(95, 233, 196, 1)',
          c2: 'rgba(62, 219, 223, 1)'
        },
        {
          c1: 'rgba(249, 169, 110, 1)',
          c2: 'rgba(248, 123, 104, 1)'
        },
        {
          c1: 'rgba(250, 84, 124, 1)',
          c2: 'rgba(247, 65, 108, 1)'
        },
        {
          c1: 'rgba(22, 178, 246, 1)',
          c2: 'rgba(31, 125, 238, 1)'
        },
        {
          c1: 'rgba(248, 202, 116, 1)',
          c2: 'rgba(252, 159, 74, 1)'
        }
      ]
    }
  },
  computed: {
    propName () {
      return this.checkList.find((item) => item.value === this.currentChecked)?.label
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    query: {
      handler (val) {
        this.getFullCharts()
      },
      deep: true
    }
  },
  mounted () {
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts && this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        this.myEcharts.resize()
      }
    },
    getFullCharts () {
      this.isLoading = true
      getFullTrend(this.query).then((resp) => {
        const { data = [] } = resp
        this.dataList = data
        this.isLoading = false
        this.changeEcharts()
      })
    },
    changeEcharts () {
      this.options.series = []
      this.options.legend.data = []
      this.options.xAxis.data = this.dataList.map((item) => item.date)
      const lineData = this.dataList.map((item, index) => item[this.currentChecked])
      this.options.series = {
          name: this.propName,
          type: 'line',
          smooth: true,
          data: lineData,
          symbol: 'circle', // 设定为实心点
          symbolSize: 4, // 设定实心点的大小
          itemStyle: {
            normal: {
              color: this.colorList[0].c1,
              lineStyle: {
                color: this.colorList[0].c1,
                width: 1.8
              }
            }
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: this.colorList[0].c2 // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            }
          }
        }
      // formatter方法
      this.options.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          if (this.isMoney(this.currentChecked)) {
            temp.value = this.moneyText + numFormat(item.value, 3, '')
          } else if (this.isRota(this.currentChecked)) {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value, 3, '')
          }
          str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
        })

        return params[0].axisValue + '<br>' + str
      }
      this.echartsInit()
    },
    echartsInit () {
      this.myEcharts && this.myEcharts.dispose()
      if (!this.$refs.chart) return
      this.myEcharts = echarts.init(this.$refs.chart, null)
      this.myEcharts.setOption(this.options)
    }
  }
}
</script>

<style lang="less">
.adx-chart-container {
  border-radius: @mediumRadius;
  height: 480px;
  position: relative;
  display: flex;
  background: #fff;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  margin-bottom: @mediumMargin;
  .adx-chart-left{
    padding: @smallMargin;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - 152px);
    height: 100%;
    .title{
      font-size: 16px;
      font-weight: 500;
      padding-left: 16px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        width: 6px;
        height: 12px;
        border-radius: 99px;
        background-color: @primary-color;
        transform: translateY(50%);
      }
    }
    .spin {
      display: inline-block;
      flex: 1 auto;
      width: 100%;
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .chart {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .adx-chart-right{
    flex: 1 auto;
    user-select: none;
    border-left: 1px solid @compBorderColor;
    text-align: center;
    display: flex;
    flex-direction: column;
    .right-head{
      padding:  10px 0;
      color: @compValueColor;
      font-weight: 500;
      line-height: 20px
    }
    .tag-list{
      flex: 1 auto;
      overflow-y: auto;
      .tag-item{
        cursor: pointer;
        padding: 8px 0;
        color: #5a607f;
        font-weight: 400;
        &.active{
          background-color: @primary-color;
          color: #fff;
        }
      }
    }
  }
}
</style>
